@font-face {
  font-family: "Babylonica";
  src: local("Babylonica"), url("./fonts/Babylonica.ttf") format("truetype");
}
@font-face {
  font-family: "DancingScript";
  src: local("DancingScript"), url("./fonts/DancingScript.ttf") format("truetype");
}
@font-face {
  font-family: "GreatVibes";
  src: local("GreatVibes"), url("./fonts/GreatVibes.ttf") format("truetype");
}
@font-face {
  font-family: "Pacifico";
  src: local("Pacifico"), url("./fonts/Pacifico.ttf") format("truetype");
}
@font-face {
  font-family: "Parisienne";
  src: local("Parisienne"), url("./fonts/Parisienne.ttf") format("truetype");
}
@font-face {
  font-family: "Sacramento";
  src: local("Sacramento"), url("./fonts/Sacramento.ttf") format("truetype");
}
@font-face {
  font-family: "Satisfy";
  src: local("Satisfy"), url("./fonts/Satisfy.ttf") format("truetype");
}
@font-face {
  font-family: "Tangerine";
  src: local("Tangerine"), url("./fonts/Tangerine.ttf") format("truetype");
}
@font-face {
  font-family: "Zeyada";
  src: local("Zeyada"), url("./fonts/Zeyada.ttf") format("truetype");
}

@font-face {
	font-family: 'Raleway-Semibold';
	src: local('Raleway-Semibold'), url("./fonts/Raleway-SemiBold.ttf") format('truetype');
}

@font-face {
	font-family: 'Raleway-Regular';
	src: local('Raleway-Regular'), url("./fonts/Raleway-BVpl.ttf") format('truetype');
}

@font-face {
	font-family: 'Raleway-Italic';
	src: local('Raleway-Italic'), url("./fonts/RalewayItalic-7Gwl.ttf") format('truetype');
}

@font-face {
	font-family: 'OpenSans-Regular';
	src: local('OpenSans-Regular'), url("./fonts/OpenSans-B9K8.ttf") format('truetype');
}

:root {
  --orange: #375f77;
  --orange80: #ED764D;
  --orange60: #F29879;
  --offblack: #313639;
  --offblack80: #4B5358;
  --offblack20: #C8CDD0;
  --offblack10: #F5F5F5;
  --grey: #333333;
  --grey60: #CECAC5;
  --grey20: #EEEDEB;

  --light-blue: #0082BE;
  --dark-blue: #19466A;
  --dark-grey: #303638;
  --light-grey: #CCCCCC;
  --light-grey-bg: #EEEDEB;
}

html,
body,
#root {
  min-width: 320px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  font-family: 'OpenSans-Regular';
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/files/images/Background_1.jpg");
  background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
  background-color: var(--light-grey-bg);
}

.accordion-expand {
  max-height: 400px;
  min-height: 400px;
  width: 100%;
}
.accordion-collapsed {
  max-height: 48px;
  min-height: 0;
  width: 100%;
}
.accordion-btn {
  background: transparent;
  color: #375f77;
  background-color: transparent !important;
  padding-left: 20px !important;
}
.accordion-btn:hover {
  background: transparent;
  color: #375f77;
}


p.copyright {
  position: absolute;
  width: 100%;
  color: var(--offblack10);
  /* line-height: 40px; */
  font-size: 8pt;
  text-align: center;
  bottom:-15px;
  background-color: black;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
}

.container-margin {
  margin-left: 2%;
  margin-top: 0.5%;
}

.modal {
  --bs-modal-margin: 5rem;
}

.modal-footer {
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.overflow-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.btn-black {
  background-color: black;
  border-color: black;
  color: var(--offblack10);
  font-weight: 600;
}

.bg-gray {
  background-color: rgb(38, 38, 38) !important;
}

.bg-gray-button {
  background-color: rgb(110, 110, 110) !important;
}

.bg-light-gray {
  background-color: #dadada;
}

.input-group-text {
  background-color: rgb(38, 38, 38);
}

.input-group-text {
  color: var(--offblack10);
}

.btn-black:hover {
  color: var(--dark-blue);
}

.sidebar-icon {
  color: var(--dark-blue);
  font-size: 22px;
  margin-top: auto;
}

.sidebar-mobile-icon {
  color: var(--dark-blue);
  font-size: 30px;
  margin-top: auto;
}

.middle-icon {
  color: rgb(88,105,114);
  font-size: 18px;
}

.btn-black .svg-inline--fa {
  color: var(--dark-blue);
}

.btn-black-alt {
  background-color: black;
  border-color: black;
  color: var(--dark-blue);
  font-weight: 600;
}

.btn-black-alt:hover {
  color: var(--offblack10);
}

.btn-black-alt .svg-inline--fa {
  color: var(--dark-blue);
}

.btn-main {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  color: var(--offblack10);
  font-weight: 600;
}

.btn-main:hover {
  color: var(--light-blue);
}

.btn-main-alt {
  background-color: var(--dark-blue);
  border-color: var(--dark-blue);
  color: var(--offblack10);
  font-weight: 600;
}

.btn-main-alt:hover {
  background-color: black;
  border-color: black;
  color: var(--dark-blue);
}

.bg-black { background-color: black; }
.bg-dark-grey { background-color: var(--dark-grey); }
.bg-light-grey { background-color: var(--light-grey); }
.bg-main{
  background-color: #262626;
} 
  
.bg-light-main { background-color: var(--light-blue); }
.text-main { color: var(--dark-blue); }

.input-borders{
  border-radius: 0px;
  border: 1px !important;
  border-style: solid !important;
}

.circle-border{
  border: 1px !important;
  border-style: solid !important;
  border-color: rgb(52,95,119) !important;
}

.input-form{
  border-radius: 0px !important;
}

.header-input {
  background-color: rgb(52,95,119);
  color: white;
  border-radius: 0px;
  height: 48px;
}

.header-delete {
  color: white;
  border-radius: 0px;
  border: 1px !important;
  border-style: solid !important;
  border-color: white;
}

.body-input {
  border-radius: 0px;
  border-color: rgb(52,95,119);
  border-style: solid;
  border-width: 1px;
}

.d-inline-box {
  display: -webkit-inline-box;
}

.color-blue {
  color: rgb(52,95,119);
}

.dotted-dashed {
  border-style: dashed !important;
  border-color: rgb(52,95,119) !important;
}

.signature-passage {
  width: 68%;
  min-height: 70px;
  margin-inline: auto;
}

.description-input{
  border-style: solid;
  border-width: 1px;
  border-color: rgb(226,226,227);
  font-style: italic;
  background-color: white !important;
  padding-left: 10px;
}

.overflow{
  overflow-y: auto;
}

.overflow-x{
  overflow-x: auto;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.fs-12{
  font-size: 12px;
}

.widget-number {
  color: rgb(52,95,119);
  font-size: xxx-large;
  font-weight: bolder;
}

.widget-icon {
  font-size: 100px;
  width: 100px;
  opacity: 0.1;
}

.widget-overlap {
  grid-column-start: 1;
  grid-row-start: none;
  margin: auto;
}

.m-a{
  margin: auto;
}

.body-inputs {
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  background-color: white;
  height: 35px;
  text-wrap: nowrap;
}

.body-inputs-mobile  {
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  background-color: white;
  height: 35px;
  width: 102px;
  text-wrap: nowrap;
  margin-bottom: 8px;
}

.send-general {
  font-size: medium;
}

.pl {
  padding-left: 0px !important;
}

.pr {
  padding-right: 0px !important;
}

.font-size-input {
  font-size: 20px
}

.ml-14{
  margin-left: 14rem;
}

.m-rl-5{
  margin: 0px 5px;
}

#body-dropdown-inputs-mobile {
  height: 35px;
  width: 138px;
  margin-bottom: 8px;
  background-color: white;
  border-radius: 0px !important;
}

#body-dropdown-inputs {
  height: 35px;
  background-color: white;
  border-radius: 0px !important;
}

#body-dropdown-inputs{
  color: black !important;
}

.border-grey {
  border-color: var(--light-grey);
}

.home-mobile-view{
  padding-bottom: 40px;
}
.vs-navbar {
  max-width: 322px !important;
  min-width: 322px !important;
}
.vs-mobile-navbar {
  max-width: 50px !important;
  min-width: 50px !important;
}
.vs-mobile-navbar-template {
  max-width: 300px !important;
  min-width: 300px !important;
}


.orange {
  background-color: var(--orange) !important;
  color: var(--offblack10);
}

Button.orange:hover {
  background-color: var(--orange60) !important;
  color: black;
}

.pb {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: auto;
}

.pr-45 {
  padding-right: 45px;
}

.options-input {
  text-align: center;
}

.flex-inherit {
  flex: inherit;
}

.d-ib {
  display: inline-block;
}

.btn-input {
  border-radius: 0px !important;
}

.ml-30 {
  margin-left: 30px;
}

Badge.orange {
  background-color: var(--orange) !important;
  color: var(--offblack10);
}

.black {
  background-color: var(--offblack) !important;
  color: var(--offblack10);
}

Button.black:hover {
  background-color: var(--offblack80) !important;
  color: var(--offblack10);
}

.form-floating>.form-select~label::after {
  background-color: rgb(38,38,38) !important;
  color: white !important;
}

.form-floating>.form-select~label {
  color: white !important;
}

.list-group-item.active {
  background-color: var(--bs-gray-300) !important;
  color: var(--bs-text-dark) !important;
  border: 1px solid var(--bs-gray-300) !important;
}

.list-group-item.bg-dark.active {
  background-color: var(--orange) !important;
  color: var(--offblack10) !important;
}

.form-floating>.form-control, .form-floating>.form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 1px;
}

.edit-list-group-item.active {
  background-color: #262626 !important;
  border-color: transparent !important; 
  color: var(--offblack10) !important;
}

.edit-list-group-item {
  border-style: solid;
  border-width: 1px;
  border-color: #262626; 
  letter-spacing: 1px;
}

.edit-top-border {
  border-top-style: solid;
  border-top-width: 1px !important;
  border-top-color: #262626; 
}

.tools-b-border {
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #262626 !important; 
}

.signature-b-border {
  border-style: solid !important;
  border-width: 1px 0px !important;
  border-color: rgb(55,95,119) !important;
}

#docsearch {
  border-color: var(--offblack80);
  background-color: var(--offblack);
  color: var(--offblack10);
}

.btn-auth{
  min-width: 165px !important;
  max-width: 165px !important;
}

.btn-popup{
  min-width: 100px !important;
  max-width: 100px !important;
}

.uploadHidden {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.package-select {
  border: 0px;
  background-color: rgb(0,0,0);
  font-weight: bold;
  letter-spacing: 2px;
}

.w-100 {
  width: 100%;
}

.rotate-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.form-floating>.form-select~label {
  letter-spacing: 1px !important;
}

.fillinput:focus, input[type="checkbox"]:focus {
  /*background-color: var(--bs-warning) !important;*/
  box-shadow: 0 0 5px rgb(52,95,119) !important;
}

.form-check-input:required:not(:checked) {
  border: 1px solid rgb(52,95,119) !important;
}

.editinput {
  -webkit-border-image: 0;
  border-radius: 0px !important;
}

.br-0 {
  border-radius: 0px;
}

.bg-w {
  background-color: white;
}

.editinput.border-warning .form-check-input {
  border: 1px solid rgb(52,95,119) !important;
}

.form-floating>.form-select~label {
  letter-spacing: 2px !important;
}

.form-floating .form-control:focus, textarea:focus {
  box-shadow: none !important;
  border: 1px solid rgb(52,95,119) !important;
}
.editinput .form-check-label {
  opacity: 1 !important;
}

.btn.btn-primary {
  background-color: #375f77;
  border: none; 
  border-radius: 0%;
  font-family: 'Raleway-Semibold';
}

.btn.btn-secondary {
  background-color: #6d8490;
  border: none; 
  border-radius: 0%;
  font-family: 'Raleway-Semibold';
}

.btn.btn-info {
  background-color: #6e6e6e;
  color: var(--offblack10);
  border: none; 
  border-radius: 0%;
  font-family: 'Raleway-Semibold';
}

.btn.btn-light {
  background-color: #375f77;
  color: var(--offblack10); 
  font-family: "Raleway-Regular";
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none; 
  border-radius: 0%;
  font-family: 'Raleway-Semibold';
}

.btn.btn-dark {
  background-color: #375f77;
  color: var(--offblack10); 
  font-family: "Raleway-Regular";
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none; 
  border-radius: 0%;
  font-family: 'Raleway-Semibold';
}

.close-btn {
  max-width: 22px;
  max-height: 22px;
}

.edit-btn {
  border-width: 1px !important;
  border-color: rgb(88,131,143) !important;
  border-style: solid !important;
  color: rgb(88,131,143);
}

.logo-image{
  width: 90%;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-auto {
  margin-right: auto;
}

.border-none {
  border-radius: 0px;
}

/*BEGIN: STYLING FOR CARD FROM TOP TO BOTTOM*/

.card-header-text {
  display: flex;
  flex-direction: row;
  font-size: 14pt;
  letter-spacing: 3px;
  font-family: 'Raleway-SemiBold';
}

.card-header-text:before,
.card-header-text:after {
  content: "" !important;
  flex: 1 1 !important;
  border-bottom: 1px solid !important;
  border-color: #375f77 !important;
  margin: auto !important;
}

.card-header-text:before {
  margin-right: 10px !important
}

.card-header-text:after {
  margin-left: 10px !important
}

/*Textbox square border styling:*/

.textbox-square-border {
  border-radius: 0%;
  font-size: 11pt;
  font-family: 'OpenSans-Regular';
}

/*Forgot password text styling:*/
.forgot-text{
  color: #375f77;
  font-size: 11pt;
  text-decoration: none;
}

.float-right{
  float: right;
}

.float-left{
  float: left;
}

.vertical-align{
  vertical-align: sub;
}

/*Buttons Styling*/

.button-general-styling{
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 3px;
  margin-left: 1.5rem;
}

.button-general-styling-mobile{
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 3px;
}

.button-login-styling{
  padding-left: 2rem;
  padding-right: 2rem;
  letter-spacing: 3px;
}

.button-smaller-styling{
  padding-left: 1rem;
  padding-right: 1rem;
  letter-spacing: 2.5px;
  margin-left: 1rem;
}

/*Evolve Slogan Styling:*/

.slogan-text{
  color: var(--offblack10);
  letter-spacing: 4px;
  font-weight: 550;
  font-size: 15pt;
  margin-left: 35%;
  margin-top: 40%;
  font-style: italic;
}

.slogan-text-verify{
  color: var(--offblack10);
  letter-spacing: 4px;
  font-weight: 550;
  font-size: 15pt;
  margin-left: 35%;
  margin-top: 5%;
  font-style: italic;
}

.custom-box{
 padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.header-text-color{
  color: #6d8490;
}

.text-spacing-sml{
  letter-spacing: 2.5px;
}

.ml-2{
  margin-left: 1.5rem;
}

.mr-2{
  margin-right: 1.5rem;
}

.modal-content  {
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important; 
}

.welcome-text{
  font-family: "Raleway-Regular";
  letter-spacing: 1px;
}

.name-text{
  font-family: 'Raleway-Italic';
  text-transform: uppercase;
}

.search-box{
  border-radius: 0%;
  background-color: #6e6e6e;
  border-color: #6d8490;
  border-right: 0px;
}

.btn-padding {
  padding: 3px 8px;
}

.help-button{
  color: #262626;
}
.btn.btn-outline-secondary{
  background-color: var(--offblack10);
  border-left: none; 
  padding-top: 10px; 
}

/*BEGIN: Styling for the cards on the dashboard*/

.card-image{
  width: 15%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -400px;
}

.w-15{
  width: 15%;
}

.w-25{
  width: 25%;
}

.flex {
  display: flex;
}

.home-card-headings{
  display: flex; 
  flex-direction: column; 
  align-items: center;
}

.align-items-center {
  align-items: center;
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {  
   opacity: 1;
}

.home-card-description {
  margin: 0 auto;
  width: 80%;
  display: grid;
  justify-items: center;
  align-content: center;
  text-align: center;
}

.home-card-headings{
  font-family: "Raleway-Regular";
  letter-spacing: 2.5px;
}

.headings-accent{
  color: #6d8490;
}

.hr-lines:before{
  content:" ";
  display: block;
  height: 1px;
  width: 75px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #375f77;
  margin-left: 20px;
}

.hr-lines{
  position: relative;
}

.hr-lines:after{
  content:" ";
  height: 1px;
  width: 75px;
  background: #375f77;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  margin-right: 20px;
}

.home-card-container{
  margin-top: 1%;
}

.logo-icon-container{
  margin-top: 15%;
}

.logo-icon{
  width: 100%;
}

.list-group{
  z-index: 2;
}

.list-group-width {
  width: 300px;
}

.list-group-mobile {
  width: 100%;
  min-height: 180px;
}

.list-group-items-mobile {
  width: 35%;
  height: 100%;
}

.created-time-stamp {
  color: var(--bs-gray-400) !important;
}

.sidecard-header1{
  letter-spacing: 2px;
  font-family: "Raleway-Semibold";
  color: #375f77;
}

.sidecard-header2{
  letter-spacing: 2px;
  font-family: "Raleway-Semibold";
  color: black;
  font-weight: bold;
}

.sub-heading{
  letter-spacing: 1px;
  font-family: "Raleway-Regular";
  color: #375f77;
}

.doc-name-text{
  font-family: "Raleway-Regular";
  color: #262626;
}

.doc-client-text{
  font-family: "Raleway-Regular";
  color: #5e5e5e; 
}

.pl-navbar-text {
  padding-left: 12px;
}

.navbar-text-main:hover {
  color: var(--offblack10);
}

.navbar-text{
  font-family: "Raleway-Regular";
  color: var(--offblack10);
  display: flex;
}

.modal-dialog {
  margin-top: 10rem !important;
}

.c-white {
  color: var(--offblack10);
}

.fw-100 {
  font-weight: 100;
}

.package-text-span {
  margin-left: 5px;
  color: #375f77 !important;
  font-size: 13px;
}

.navbar-text-span {
  margin-top: 5px;
  margin-left: 10px;
  font-weight: 500;
  letter-spacing: 1pt;
  font-size: 9pt !important;
}

.sign-text-span {
  letter-spacing: 1pt;
}

.fs-18 {
  font-size: 18px !important;
}

.d-flex-child > button {
  display: flex;
}

.d-flex-child > button::after {
  align-self: center;
}

.bg-light-blue {
  background-color: rgb(109,132,144) !important;
}

.as-c {
  align-self: center;
}

.w-a {
  width: auto;
}

.ml-auto {
  margin-left: auto;
}

.navbar-text-span-headers {
  margin-top: 5px;
  margin-left: 10px;
  font-weight: 500;
  letter-spacing: 1pt;
  font-weight: bold;
  margin-bottom: auto;
  letter-spacing: 2px;
}

.signer-edit {
  height: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-size: 10px;
  margin-top: 5px;
  padding-top: 3px;
  margin-left: auto !important;
}

.title-first-word {
  color: #375f77 !important;
}

.align-self-center {
  align-self: center;
}

.ml-1 {
  margin-left: 5px;
}

.auth-icons {
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 5px;
}

.card-button {
  font-family: 'Raleway-SemiBold';
}

.p-sides{
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.navigation-container {
  text-align: end;
  color: white;
}

.navbar-list-item{
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #262626; 
}

.navbar-list-item-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #262626; 
}

.navbar-tools-pl {
  padding-left: 42px;
}

.navbar-options-pl {
  padding-left: 25px;
}

.bc-t {
  border-color: transparent;
}

.navbar-fc:focus {
  background-color: rgb(38, 38, 38);
  border-color: var(--light-blue);
}

.topbar-fc:focus {
  background-color: var(--offblack10);
  border-color: var(--light-blue);
}

.bw-0 {
  border-width: 0px;
}

.br-0 {
  border-radius: 0px;
}

.tb-persentage {
  background-color: #818181;
}

.auth-icons {
  display: inline-flex;
  vertical-align: bottom;
  margin-right: 5px;
}

.navigation-container {
  text-align: end;
}

.navbar-plaintext {
  width: 60%;
}
.navbar-plaintext-mobile {
  width: 100%;
}
.navbar-plaintext-mobile-1650 {
  width: 100%;
}

.vw-100{
  width: 100vw;
}

.navbar-button-template{
  max-width: 40px;
  max-height: 33px;
}
.popup-mobile{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

/* border-style: solid;
border-width: 5px;
border-color: red; */

/* .home-card-header-text:before {
  margin-right: 200px
}

.home-card-header-text:after {
  margin-left: 200px
} */

/*END: Styling for the cards on the dashboard*/


/* .no-border-radius{
  border-radius: 0;
}

.modal-conten{
  border-radius: 0% !important;
} */

/*END*/
